<template>
  <b-container fluid>
    <b-row>
      <b-col lg="3" v-for="(item,index) in cards" :key="`basic-${index}`">
        <iq-card body-class="text-center" :class="item.active ? 'bg-primary text-white' : ''">
          <template v-slot:body>
            <span class="font-size-16 text-uppercase" v-if="item.plan !== ''">{{ item.plan }}</span>
            <h2 class="mb-4 display-3 font-weight-bolder" :class="item.active ? 'text-white' : ''">{{ item.amount }}<small class="font-size-14 " :class="item.active ? 'text-white' : 'text-muted'">{{ item.duration }}</small>
            </h2>
            <ul class="list-unstyled mb-0 ">
              <li>Lorem ipsum dolor sit amet</li>
              <li>Consectetur adipiscing elit</li>
              <li>Integer molestie at massa</li>
              <li>Facilisis in pretium nisl aliquet</li>
              <li>Nulla volutpat aliquam velit</li>
            </ul>
            <b-button :variant="item.active ? 'light' : 'primary'" class="mt-5 " :class="item.buttonClass">{{ item.button }}</b-button>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="3" v-for="(item,index) in bgCards" :key="index">
        <div class="iq-card bg-dark text-white text-center iq-mb-3">
          <img :src="item.bgImage" alt="" class="card-img rounded">
          <div class="card-img-overlay">
            <h2 class="mb-4 display-3 font-weight-bolder text-white">
              {{ item.amount }}
              <small class="font-size-14 text-white" >{{ item.duration }}</small>
            </h2>
            <ul class="list-unstyled mb-0 ">
              <li>Lorem ipsum dolor sit amet</li>
              <li>Consectetur adipiscing elit</li>
              <li>Integer molestie at massa</li>
              <li>Facilisis in pretium nisl aliquet</li>
              <li>Nulla volutpat aliquam velit</li>
            </ul>
            <b-button :variant="item.active ? 'light' : 'primary'" class="mt-3 " :class="item.buttonClass">{{ item.button }}</b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'

export default {
  name: 'Pricing1',
  mounted () {
    core.index()
  },
  data () {
    return {
      cards: [
        {
          plan: 'Basic',
          amount: '$26',
          duration: '/ Month',
          active: false,
          description: 'Lorem ipsum dolor sit amet Consectetur adipiscing elit Integer molestie lorem at massa Facilisis in pretium nisl aliquet Nulla volutpat aliquam velit',
          button: 'Start Starter',
          buttonClass: '',
          bgImage: false
        },
        {
          plan: 'Basic',
          amount: '$99',
          duration: '/ Month',
          active: true,
          description: 'Lorem ipsum dolor sit amet Consectetur adipiscing elit Integer molestie lorem at massa Facilisis in pretium nisl aliquet Nulla volutpat aliquam velit',
          button: 'Start Starter',
          buttonClass: 'btn-block',
          bgImage: false
        },
        {
          plan: 'Basic',
          amount: '$39',
          duration: '/ Month',
          active: false,
          description: 'Lorem ipsum dolor sit amet Consectetur adipiscing elit Integer molestie lorem at massa Facilisis in pretium nisl aliquet Nulla volutpat aliquam velit',
          button: 'Start Starter',
          buttonClass: '',
          bgImage: false
        },
        {
          plan: 'Basic',
          amount: '$25',
          duration: '/ Month',
          active: false,
          description: 'Lorem ipsum dolor sit amet Consectetur adipiscing elit Integer molestie lorem at massa Facilisis in pretium nisl aliquet Nulla volutpat aliquam velit',
          button: 'Start Starter',
          buttonClass: '',
          bgImage: false
        }
      ],
      bgCards: [
        {
          plan: 'Basic',
          amount: '26',
          duration: '$/ Month',
          active: false,
          description: 'Lorem ipsum dolor sit amet Consectetur adipiscing elit Integer molestie lorem at massa Facilisis in pretium nisl aliquet Nulla volutpat aliquam velit',
          button: 'Get Started',
          buttonClass: '',
          bgImage: require('../../assets/images/page-img/25.jpg')
        },
        {
          plan: 'Basic',
          amount: '99',
          duration: '$/ Month',
          active: false,
          description: 'Lorem ipsum dolor sit amet Consectetur adipiscing elit Integer molestie lorem at massa Facilisis in pretium nisl aliquet Nulla volutpat aliquam velit',
          button: 'Get Started',
          buttonClass: '',
          bgImage: require('../../assets/images/page-img/26.jpg')
        },
        {
          plan: 'Basic',
          amount: '39',
          duration: '$/ Month',
          active: false,
          description: 'Lorem ipsum dolor sit amet Consectetur adipiscing elit Integer molestie lorem at massa Facilisis in pretium nisl aliquet Nulla volutpat aliquam velit',
          button: 'Get Started',
          buttonClass: '',
          bgImage: require('../../assets/images/page-img/27.jpg')
        },
        {
          plan: 'Basic',
          amount: '25',
          duration: '$/ Month',
          active: false,
          description: 'Lorem ipsum dolor sit amet Consectetur adipiscing elit Integer molestie lorem at massa Facilisis in pretium nisl aliquet Nulla volutpat aliquam velit',
          button: 'Get Started',
          buttonClass: '',
          bgImage: require('../../assets/images/page-img/28.jpg')
        }
      ]
    }
  }
}
</script>
